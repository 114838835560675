import React, { Component } from 'react'
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { carregarPermissoes } from '../../usuario/usuarioActions';

class Menu extends Component {

	state = {
		cadastroAberto: false,
		logisticaAberta: false,
		despesaAberta: false,
		oficinaAberta: false,
		analiseAberta: false
	};

	componentDidMount() {
		this.props.carregarPermissoes(this.props.sessao ? this.props.sessao : null);
		let idInterval = setInterval(() => {
			if (this.props.sessao) {
				this.props.carregarPermissoes(this.props.sessao);
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		let { sessao } = this.props;

		if (this.props.permissoes.naoCarregado) {
			return <div />
		}

		if ((selecionado == '/usuario' || selecionado == '/motorista' || selecionado == '/despesaProduto' || selecionado == '/posto' || selecionado == '/pontoTipo' || selecionado == '/ponto' || selecionado == '/veiculoTipo'
			 || selecionado == 'rede' || selecionado == 'formaPagamento' || selecionado == '/rota' || selecionado == '/setor' || selecionado == '/veiculo' 
			 || selecionado == '/veiculoCarreta' || selecionado == '/checklist' || selecionado == '/contaBancaria')) {
			if (!this.state.cadastroAberto) {
				this.setState({ ...this.state, cadastroAberto: true });
			}

			if ((selecionado == '/usuario' && !this.props.permissoes.acesso_usuario) || (selecionado != '/usuario' && !this.props.permissoes.acesso_cadastro)) {
				if (this.props.permissoes.acesso_logistica) {
					window.location = '/';
				} else if (this.props.permissoes.acesso_viagem) {
					window.location = '/#/viagemLancamento';
				} else if (this.props.permissoes.acesso_despesa) {
					window.location = '/#/despesaViagem';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			}

		}

		if ((selecionado == '/#/viagemLancamento' || selecionado == '/' || selecionado == '/#' || selecionado == '/#/' || selecionado == '/terceiro' || selecionado == '/cotacao' || selecionado == '/planejamentoViagem')) {
			if (!this.state.logisticaAberta) {
				this.setState({ ...this.state, logisticaAberta: true });
			}

			if (selecionado == '/#/viagemLancamento' && !this.props.permissoes.acesso_viagem) {
				if (this.props.permissoes.acesso_logistica) {
					window.location = '/';
				} else if (this.props.permissoes.acesso_despesa) {
					window.location = '/#/despesaViagem';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			} else if (!this.props.permissoes.acesso_logistica) {
				
				if (this.props.permissoes.acesso_viagem) {
					window.location = '/#/viagemLancamento';
				} else if (this.props.permissoes.acesso_despesa) {
					window.location = '/#/despesaViagem';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			}
		}

		if ((selecionado == '/despesaViagem' || selecionado == '/abastecida' || selecionado == '/vale' || selecionado == '/devolucao')) {
			if (!this.state.despesaAberta) {
				this.setState({ ...this.state, despesaAberta: true });
			}
			if (!this.props.permissoes.acesso_despesa) {
				if (this.props.permissoes.acesso_logistica) {
					window.location = '/';
				} else if (this.props.permissoes.acesso_viagem) {
					window.location = '/#/viagemLancamento';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			}
		}
		
		if ((selecionado == selecionado == '/ocorrencia' || selecionado == '/ordemServico')) {
			if (!this.state.oficinaAberta) {
				this.setState({ ...this.state, oficinaAberta: true });
			}
			if (!this.props.permissoes.acesso_despesa) {
				if (this.props.permissoes.acesso_logistica) {
					window.location = '/';
				} else if (this.props.permissoes.acesso_viagem) {
					window.location = '/#/viagemLancamento';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			}
		}

		if ((selecionado == selecionado == '/dashboardLogistica' || selecionado == '/dashboardManutencao' || selecionado == '/dashboardFinanceiro')) {
			if (!this.state.analiseAberta) {
				this.setState({ ...this.state, analiseAberta: true });
			}
			if (!this.props.permissoes.acesso_despesa) {
				if (this.props.permissoes.acesso_logistica) {
					window.location = '/';
				} else if (this.props.permissoes.acesso_viagem) {
					window.location = '/#/viagemLancamento';
				} else if (this.props.permissoes.acesso_cadastro) {
					window.location = '/#/motorista';
				} else if (this.props.permissoes.acesso_usuario) {
					window.location = '/#/usuario';
				} else if (selecionado != '/') {
					window.location = '/';
				}
			}
		}

		return (
			<nav className="mt-2">
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					{this.props.permissoes.acesso_cadastro ? (
						<li class={`nav-item has-treeview menu-open`}>
							<a href='#' class='nav-link' onClick={() => this.setState({ ...this.state, cadastroAberto: !this.state.cadastroAberto })}>
								<i class='' style={{ marginLeft: -8, fontWeight: 'bold', fontStyle: 'normal' }}>Cadastros</i>
							</a>
							<ul class='nav' style={{ display: this.state.cadastroAberto ? 'block' : 'none' }}>
								{this.props.permissoes.acesso_usuario ? (
						        	<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
								) : null}
								<MenuItem path='motorista' label='Motorista' icon='fas fa-user' active={selecionado == '/motorista'}  />
								<MenuItem path='despesaProduto' label='Despesa Produto' icon='fas fa-box' active={selecionado == '/despesaProduto'} />
								<MenuItem path='rede' label='Rede' icon='fas fa-list' active={selecionado == '/rede'} />
								<MenuItem path='formaPagamento' label='Forma de Pagamento' icon='fas fa-dollar-sign' active={selecionado == '/formaPagamento'} />
								<MenuItem path='posto' label='Posto' icon='fas fa-gas-pump' active={selecionado == '/posto'} />
								<MenuItem path='pontoTipo' label='Tipo de Fornecedor' icon='fas fa-map-pin' active={selecionado == '/pontoTipo'} />
								<MenuItem path='ponto' label='Fornecedor' icon='fas fa-map' active={selecionado == '/ponto'} />
								<MenuItem path='veiculoTipo' label='Tipo de Veículo' icon='fas fa-th-list' active={selecionado == '/veiculoTipo'}  />
								<MenuItem path='veiculo' label='Veículo' icon='fas fa-truck' active={selecionado == '/veiculo'}  />
								<MenuItem path='veiculoCarreta' label='Carreta' icon='fas fa-truck-loading' active={selecionado == '/veiculoCarreta'}  />
								<MenuItem path='produto' label='Produto' icon='fas fa-list-alt' active={selecionado == '/produto'}  />
								<MenuItem path='rota' label='Rota' icon='fas fa-route' active={selecionado == '/rota'}  />
								<MenuItem path='setor' label='Setor' icon='fas fa-stream' active={selecionado == '/setor'}  />
								<MenuItem path='checklist' label='Checklist' icon='fas fa-tasks' active={selecionado == '/checklist'} />
								<MenuItem path='contaBancaria' label='Conta Bancária' icon='fas fa-money-check' active={selecionado == '/contaBancaria'} />
							</ul>
						</li>
					) : null}

					{this.props.permissoes.acesso_logistica || this.props.permissoes.acesso_viagem ? (
						<li class={`nav-item has-treeview menu-open`}>
							<a href='#' class='nav-link' onClick={() => this.setState({ ...this.state, logisticaAberta: !this.state.logisticaAberta })}>
								<i class='' style={{ marginLeft: -8, fontWeight: 'bold', fontStyle: 'normal' }}>Logística</i>
							</a>
							<ul class='nav' style={{ display: this.state.logisticaAberta ? 'block' : 'none' }}>
								{this.props.permissoes.acesso_viagem ? (
									<MenuItem path='viagemLancamento' label='Viagem Lançamento' icon='fas fa-road' active={selecionado == '/#/viagemLancamento'} />
								) : null}
								{this.props.permissoes.acesso_logistica ? (
									<>										
										<MenuItem path='/' label='Frota' icon='fas fa-truck-moving' active={selecionado == '/'} />
										{this.props.sessao && !this.props.sessao.id_motorista ? (
											<MenuItem path='/terceiro' label='Terceiros' icon='fas fa-truck' active={selecionado == '/terceiro'} />
										) : null}
										{this.props.sessao && !this.props.sessao.id_motorista ? (
											<MenuItem path='cotacao' label='Cotação' icon='fas fa-dollar-sign' active={selecionado == '/cotacao'} />
										) : null}
										{this.props.sessao && !this.props.sessao.id_motorista ? (
											<MenuItem path='planejamentoViagem' label='Planejamento Viagem' icon='fas fa-file-invoice-dollar' active={selecionado == '/planejamentoViagem'} />
										) : null}
									</>
								) : null}
							</ul>
						</li>
					) : null}

					{this.props.permissoes.acesso_despesa ? (
						<li class={`nav-item has-treeview menu-open`}>
							<a href='#' class='nav-link' onClick={() => this.setState({ ...this.state, despesaAberta: !this.state.despesaAberta })}>
								<i class='' style={{ marginLeft: -8, fontWeight: 'bold', fontStyle: 'normal' }}>Financeiro</i>
							</a>
							<ul class='nav' style={{ display: this.state.despesaAberta ? 'block' : 'none' }}>
								<MenuItem path='multa' label='Multa' icon='fas fa-receipt' active={selecionado == '/multa'} />
								<MenuItem path='' label='Débitos Empresa' icon='fas fa-search-dollar' active={selecionado == '/debitosEmpresa'} />
								<MenuItem path='despesaViagem' label='Despesa' icon='fas fa-money-check-alt' active={selecionado == '/despesaViagem'} />
								<MenuItem path='abastecida' label='Abastecida' icon='fas fa-gas-pump' active={selecionado == '/abastecida'} />
								<MenuItem path='vale' label='Vale' icon='fas fa-money-bill' active={selecionado == '/vale'} />
								<MenuItem path='devolucao' label='Devolução' icon='fas fa-money-check' active={selecionado == '/devolucao'} />
							</ul>
						</li>
					) : null}
					{this.props.permissoes.acesso_despesa ? (
						<li class={`nav-item has-treeview menu-open`}>
							<a href='#' class='nav-link' onClick={() => this.setState({ ...this.state, oficinaAberta: !this.state.oficinaAberta })}>
								<i class='' style={{ marginLeft: -8, fontWeight: 'bold', fontStyle: 'normal' }}>Oficina</i>
							</a>
							<ul class='nav' style={{ display: this.state.oficinaAberta ? 'block' : 'none' }}>
								{this.props.sessao && (!this.props.sessao.id_motorista || (this.props.sessao.id_motorista == 113 || this.props.sessao.id_motorista == 117 || this.props.sessao.id_motorista == 118)) ? (
									<MenuItem path='ocorrencia' label='Ocorrência' icon='fas fa-exclamation-triangle' active={selecionado == '/ocorrencia'} />
								) : null}
								{this.props.sessao && (!this.props.sessao.id_motorista) ? (
									<MenuItem path='ordemServico' label='Ordem de Serviço' icon='fas fa-file-alt' active={selecionado == '/ordemServico'} />
								) : null}
							</ul>
						</li>
					) : null}

					{this.props.permissoes.dashboard_logistica || this.props.permissoes.dashboard_oficina || this.props.permissoes.dashboard_financeiro ? (
						<li class={`nav-item has-treeview menu-open`}>
							<a href='#' class='nav-link' onClick={() => this.setState({ ...this.state, analiseAberta: !this.state.analiseAberta })}>
								<i class='' style={{ marginLeft: -8, fontWeight: 'bold', fontStyle: 'normal' }}>Central de Análises</i>
							</a>
							<ul class='nav' style={{ display: this.state.analiseAberta ? 'block' : 'none' }}>
								{this.props.permissoes.dashboard_logistica ? (
									<MenuItem path='dashboardLogistica' label='Dashboard Logística' icon='fas fa-chart-bar' active={selecionado == '/dashboardLogistica'} />
								) : null}
								{this.props.permissoes.dashboard_oficina ? (
									<MenuItem path='dashboardManutencao' label='Dashboard Oficina' icon='fas fa-chart-line' active={selecionado == '/dashboardManutencao'} />
								) : null}
								{this.props.permissoes.dashboard_financeiro ? (
									<MenuItem path='dashboardFinanceiro' label='Dashboard Financeiro' icon='fas fa-chart-pie' active={selecionado == '/dashboardFinanceiro'} />
								) : null}
							</ul>
						</li>
					) : null}
		    	</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarPermissoes }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
