import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Map from '../common/map/map';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela,
	initForm
} from './rotaActions';

class RotaForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let origem = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_origem).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];
		let destino = this.props.listaCidade.filter(item => item.id == this.props.formularioValues.id_cidade_destino).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0];

		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardHeader>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 6 6'
									readOnly={readOnly} />

								<Field
									name='codigo'
									component={LabelAndInput}
									label='Código GR' placeholder='Informe o código'
									cols='12 6 3 3'
									readOnly={readOnly} />
									
								<Field
									name='id_tipo'
									component={Select}
									label='Tipo' placeholder='Informe o tipo'
									options={this.props.listaTipo}
									cols='12 6 3 3'
									readOnly={readOnly} />

								<Field
									name='id_estado_origem'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Origem'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_origem'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_origem)}
									label='Cidade de Origem'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>

							<label>Pontos ({this.state.quantidadeItens || (this.props.formularioValues.itens || []).length})</label>
							{(this.props.formularioValues.itens || []).filter(item => item.ativo).map(item => (
								<ContentCard key={item.id || item.id_aux}>
									<ContentCardBody>
										<Row>
											<Select
												name='id_estado'
												options={this.props.listaEstado}
												label='Estado'
												cols='12 5 5 5'
												placeholder='Selecione o estado'
												readOnly={readOnly}
												value={item.id_estado}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_estado: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_cidade_carga'
												options={this.props.listaCidade.filter(values => values.id_estado == item.id_estado)}
												label='Cidade'
												cols='12 5 5 5'
												placeholder='Selecione a cidade'
												readOnly={readOnly}
												value={item.id_cidade}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_cidade: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

											<Select
												name='id_tipo'
												options={[{ id: 1, valor: 'Coleta' }, { id: 2, valor: 'Entrega' }]}
												label='Tipo'
												cols='12 2 2 2'
												placeholder='Selecione o tipo'
												readOnly={readOnly}
												value={item.id_tipo}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														itens: this.props.formularioValues.itens.map(itemAux => {
															if ((item.id || item.id_aux) == (itemAux.id || itemAux.id_aux)) {
																return {
																	...itemAux,
																	id_tipo: data
																};
															} else {
																return itemAux;
															}
														})
													})
												}}  />

										</Row>
									</ContentCardBody>
								</ContentCard>
							))}

							<Row>
								<Grid cols='6 6 3 2' style={{ paddingBottom: 28 }}>
									<Button
										text='Adicionar'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											let lista = this.props.formularioValues.itens || [];
											lista.push({
												id_aux: new Date().getTime(),
												ativo: true
											});
											this.props.initForm({
												...this.props.formularioValues,
												itens: lista
											});
											this.setState({
												...this.state,
												quantidadeItens: lista.filter(itemAux => itemAux.ativo).length
											});
										}} />
								</Grid>
							</Row>

							<Row>
								<Field
									name='id_estado_destino'
									component={Select}
									options={this.props.listaEstado}
									label='Estado de Destino'
									cols='12 12 6 6'
									placeholder='Selecione o estado'
									readOnly={readOnly} />

								<Field
									name='id_cidade_destino'
									component={Select}
									options={this.props.listaCidade.filter(item => item.id_estado == this.props.formularioValues.id_estado_destino)}
									label='Cidade de Destino'
									cols='12 12 6 6'
									placeholder='Selecione a cidade'
									readOnly={readOnly} />
							</Row>
						</ContentCardBody>
					</Form>
				</ContentCard>
				{origem && destino ? (
					<Map
						origem={origem}
						itens={(this.props.formularioValues.itens || []).map(item => this.props.listaCidade.filter(value => value.id == item.id_cidade).map(item => ({ descricao: item.valor, latitude: item.latitude, longitude: item.longitude }))[0]).filter(item => item)}
						destino={destino}
						pontos={this.props.listaPosto}
						rota={this.props.formularioValues.rota}
						rotaCarregada={this.props.formularioValues.rotaCarregada}
						setRota={rota => {
							this.props.initForm({
								...this.props.formularioValues,
								rotaCarregada: rota.rotaCarregada,
								rota: {
									origin: rota.origin,
									destino: rota.destino,
									waypoints: rota.waypoints,
									distancia: rota.distancia,
									passos_localizacao: rota.passos_localizacao,
									link_google_maps: rota.link_google_maps,
									pontos: rota ? rota.pontos : [],
									itens: rota ? rota.itens : [],
									overview_polyline: rota.overview_polyline || null
								},
								postos: rota ? rota.pontos : []
							});
						}} />
				) : null}
				<ContentCard>
					<ContentCardBody>
						<table responsive>
							<thead>
								<Tr>
									<th colspan={1}>Postos</th>
									<th colspan={1}></th>
								</Tr>
							</thead>
							<tbody>
								{(this.props.formularioValues.postos || []).map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.descricao}</Td>
									</Tr>
								))}
							</tbody>
						</table>
					</ContentCardBody>
				</ContentCard>

			</>
		)
	}
}

RotaForm = reduxForm({ form: 'rotaForm', destroyOnUnmount: false })(RotaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('rotaForm')(state),
	registro: state.rota.registro,
	listaEstado: state.rota.listaEstado,
	listaCidade: state.rota.listaCidade,
	listaPosto: state.rota.listaPosto,
	listaTipo: state.rota.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela,
	initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RotaForm);
