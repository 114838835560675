import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import confirmDialog from '../common/dialog/confirmDialog';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ROTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'ROTA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {

    return [
        initialize('rotaForm', registro)
    ];
}

export function getLista() {

	return (dispatch, getState) => {

		axios.get(`${consts.API_URL}/rota?
			&id_cidade=${getState().rota.filtro.id_cidade || null}
			&resumido=true
		`).then(resp => {
			console.log(resp.data);
			
			dispatch({
		        type: 'ROTA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	};
}

export function carregar(id, excluir = false) {

	return (dispatch, getState) => {

		axios.get(`${consts.API_URL}/rota?&id=${id}`).then(resp => {
			dispatch(initForm({
				...resp.data[0]
			}));
			dispatch(setModoTela(excluir ? 'exclusao' : 'cadastro', {
				...resp.data[0]
			}));
		}).catch(e => {
			setErroAPI(e);
		});
	};
}

export function getListaRotaViagem() {
    const request = axios.get(`${consts.API_URL}/rota/listarSelect`);
    return {
        type: 'VIAGEM_ROTA_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/rota`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaRotaViagem);
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/rota`, {
				...registro
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaRotaViagem);
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/rota?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaRotaViagem);
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Estado
export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
    return {
        type: 'ROTA_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

//Cidade
export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
    return {
        type: 'ROTA_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPosto() {
    const request = axios.get(`${consts.API_URL}/posto/listarSelect`);
    return {
        type: 'ROTA_POSTO_SELECT_LISTADO',
        payload: request
    };
}
