import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './despesaViagemForm';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Select from '../common/form/select';
import Aguardando from '../common/template/aguardando';

import { 
	setModoTela, initForm, buscarImagens, salvar, excluir, getLista, setFiltro,
	getListaCidade, getListaProduto, getListaMotorista, getListaUsuario, salvarAprovado 
} from './despesaViagemActions';

class DespesaViagem extends Component {

	state = {
		linhaSelecionada: null,
		id_status: 1,
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaCidade();
		this.props.getListaProduto();
		this.props.getListaMotorista();
		this.props.getListaUsuario();
    }

    render() {
        return (
            <div>

				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
				
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								disabled={this.props.permissoes.acesso_despesa != 2}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({ 
										datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										id_motorista: this.props.sessao && this.props.sessao.id_motorista ? this.props.sessao.id_motorista : null
									});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<Select
							name='id_status'
							label='Status' placeholder='Informe o status'
							cols='12 12 4 4'
							options={[{ id: 1, valor: 'Aguardando' }, { id: 2, valor: 'Não Aprovadas' }, { id: 3, valor: 'Aprovadas' }]}
							value={this.state.id_status}
							onChange={(item) => {
								this.setState({
									...this.state,
									id_status: item
								});
							}}/>
					</Row>
					<Row>
						<LabelAndInputMask
							label='Data Inicial' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								this.props.getLista();
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								this.props.getLista();
							}} />
					</Row>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Data Inclusão</Th>
								<Th alignCenter>Data Documento</Th>
								<Th >Motorista</Th>
								<Th >Fornecedor</Th>
								<Th >Cidade / UF</Th>
								<Th >Contato</Th>
								<Th >Nota Fiscal</Th>
								<Th >Pagamento</Th>
								<Th >Produto</Th>
								<Th >Valor</Th>
								<Th >Aprovado Por</Th>
								<Th alignCenter>Aprovado</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => 
								this.props.sessao && (!this.props.sessao.id_motorista || this.props.sessao.id_motorista == item.id_motorista)
							).filter(item => {
								if (this.state.id_status == 1) {
									return item.aprovado == null;
								} else if (this.state.id_status == 2) {
									return item.aprovado == false;
								} else if (this.state.id_status == 3) {
									return item.aprovado
								} else {
									return true;
								}
							}).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_motorista} ${item.fornecedor} ${item.nome_cidade || ''} ${item.telefone || ''} 
										${item.pagamento_motorista ? 'Motorista' : 'Empresa'} ${item.nome_despesa_produto} ${item.nota_fiscal || ''}
										${item.nome_usuario_aprovacao}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa)}</Td>
									<Td>{item.nome_motorista}</Td>
									<Td>{item.fornecedor}</Td>
									<Td>{item.nome_cidade}</Td>
									<Td>{item.telefone}</Td>
									<Td>{item.nota_fiscal}</Td>
									<Td>{item.pagamento_motorista ? 'Motorista' : 'Empresa'}</Td>
									<Td>{item.nome_despesa_produto}</Td>
									<Td>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td>{item.nome_usuario_aprovacao}</Td>
									<Td>
										{(this.props.sessao && !this.props.sessao.id_motorista) &&
										<select
											className='form-control'
											readOnly={item.aprovado ? 'readOnly' : null}
											value={item.aprovado ? 1 : (item.aprovado == false ? 2 : 3)}
											onChange={data => {
												this.props.salvarAprovado({
													...item,
													datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2),
													aprovado: data.target.value == 1 ? true : (data.target.value == 2 ? false : null)
												});
											}}>
											<option value='1'>Sim</option>
											<option value='2'>Não</option>
											<option value='3'>Aguardando</option>
										</select>}
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.buscarImagens(item.id);
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={this.props.permissoes.acesso_despesa != 2}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.initForm({
													...item,
													datahora_despesa: DateFormat.formatarDataHoraSqlParaTela(item.datahora_despesa),
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
													valor: FormatUtils.formatarValorTela(item.valor, 2)
												});
												this.props.buscarImagens(item.id);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.despesaViagem.modoTela,
	aguardando: state.despesaViagem.aguardando,
	lista: state.despesaViagem.lista,
	filtro: state.despesaViagem.filtro,
	permissoes: state.usuario.permissoes
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, buscarImagens, salvar, excluir, getLista, setFiltro, 
	getListaCidade, getListaProduto, getListaMotorista, getListaUsuario, salvarAprovado 
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DespesaViagem);
