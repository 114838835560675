import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import LabelAndInputDate from '../common/form/labelAndInputDate';

import {
	setModoTela, initForm
} from './veiculoActions';

class VeiculoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

	render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';



		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='placa'
								component={LabelAndInput}
								label='Placa' placeholder='Informe a placa'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{!this.props.formularioValues.carreta ? (
								<Field
									name='cm'
									component={LabelAndInput}
									label='CM' placeholder='Informe o CM'
									cols='12 12 12 12'
									readOnly={readOnly} />
							) : null}

							<Field
								name='terceiro'
								component={LabelAndCheckbox}
								label='Terceiro' placeholder='Informe se é terceiro'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Select
								name='eixos'
								options={[
									{ id: 1, valor: '1' }, { id: 2, valor: '2' }, { id: 3, valor: '3' }, { id: 4, valor: '4' }, 
									{ id: 5, valor: '5' }, { id: 6, valor: '6' }, { id: 7, valor: '7' }, { id: 8, valor: '8' }]}
								label='Eixos'
								cols='12 12 12 12'
								placeholder='Selecione a quantidade de eixos'
								readOnly={readOnly}
								value={(this.props.formularioValues.eixos || []).length}
								onChange={value => {
									let lista = [];
									for	(let i = 0; i < value; i++) {
										lista.push((this.props.formularioValues.eixos || [])[i] || '');
									}
									this.props.initForm({
										...this.props.formularioValues,
										eixos: lista
									});
								}} />

							{(this.props.formularioValues.eixos || []).map((eixo, i) => (
								<Select key={i}
									name='eixo'
									label={`Eixo ${i+1}`} placeholder='Informe os pneus'
									cols='6 4 3 2'
									readOnly={readOnly}
									value={eixo}
									options={[{ id: 2, valor: '2' }, { id: 4, valor: '4' }]}
									onChange={data => {
										this.props.initForm({
											...this.props.formularioValues,
											eixos: this.props.formularioValues.eixos.map((item, j) => {
												if (i == j) {
													return data;
												} else {
													return item;
												}
											})
										});
									}} />
							))}

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		)
	}


}

VeiculoForm = reduxForm({ form: 'veiculoForm', destroyOnUnmount: false })(VeiculoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues('veiculoForm')(state),
	registro: state.veiculo.registro

});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VeiculoForm);
